import React from 'react';
import styled, { css } from 'styled-components';
import Check from '../../../images/check-circle-filled.svg';
import Arrow from '../../../images/hx_arrow-narrow-right-filled.svg';
import Particles from '../../../images/product-assets/particles.png';
import Authenticate from '../../../images/logos/Authenticate_Logo.svg';
import useIsPageLoaded from '../../../hooks/useIsPageLoaded';
import { useModal } from '../../../context/ModalContext';

const Container = styled.section`
    background: #0c1a68;
`;

const ContentBox = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 54px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 44px;
    place-items: center center;

    @media screen and (max-width: 750px) {
        grid-template-columns: 1fr;
    }
`;

const ImageSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const MainLogo = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    @media screen and (max-width: 1024px) {
        width: 100px;
        height: auto;
    }
`;
const Animation = styled.img`
    height: auto;
    max-width: 450px;
    height: auto;

    @media screen and (max-width: 1024px) {
        max-width: 350px;
    }

    @media screen and (max-width: 520px) {
        max-width: 100%;
    }
`;

const InfoSection = styled.div``;

const Heading = styled.div`
    font-size: 44px;
    font-weight: 600;
    line-height: 62px;
    color: #fff;
    margin-bottom: 24px;

    & span {
        color: #bdb4fe;
    }

    @media screen and (max-width: 1024px) {
        font-size: 20px;
        line-height: 28px;
    }
`;

const List = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-bottom: 18px;

    @media screen and (max-width: 750px) {
        margin-bottom: 12px;
    }
`;

const Icon = styled.img`
    width: 32px;
    height: 32px;
`;

const Label = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #fff;

    @media screen and (max-width: 750px) {
        font-size: 14px;
        line-height: 18px;
    }
`;

const BtnGroup = styled.div`
    padding-top: 18px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
`;

const SharedCtaStyles = css`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #0c1a68;
    background: #fff;
    padding: 7px 16px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s ease;

    &:hover {
        transform: scale(1.06);
    }

    @media screen and (max-width: 520px) {
        justify-content: center;
        flex-wrap: wrap;
        font-family: Rubik;
        font-size: 14px;
        line-height: 16.59px;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            background: none;
            color: #fff;
        `};
`;
const CtaLink = styled.a`
    ${SharedCtaStyles}
`;

const CtaButton = styled.button`
    ${SharedCtaStyles}
    outline: none;
`;

function VerificationCosts() {
    const { isLoaded } = useIsPageLoaded();
    const { handleOpen } = useModal();
    const sourceImg = !isLoaded
        ? Particles
        : 'https://cdn.authenticating.com/public/website/product_pages/optimized_particle.gif';

    return (
        <Container>
            <ContentBox>
                <ImageSection>
                    <MainLogo src={Authenticate} alt="Authenticate" />
                    <Animation src={sourceImg} alt="Animation" />
                </ImageSection>
                <InfoSection>
                    <Heading>
                        With Authenticate, you can save up to <span>73%</span> on yearly
                        verification costs
                    </Heading>
                    <List>
                        <Icon src={Check} alt="check mark" />
                        <Label>Instant turnaround time</Label>
                    </List>
                    <List>
                        <Icon src={Check} alt="check mark" />
                        <Label>
                            More than 4,200 types of professional licenses can be verified
                        </Label>
                    </List>
                    <List>
                        <Icon src={Check} alt="check mark" />
                        <Label>15,000+ Institutions Covered</Label>
                    </List>
                    <BtnGroup>
                        <CtaLink
                            href="https://portal.authenticate.com/register"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Get Started <img src={Arrow} alt="arrow" />
                        </CtaLink>
                        <CtaButton secondary type="button" onClick={handleOpen}>
                            Schedule a Demo
                        </CtaButton>
                    </BtnGroup>
                </InfoSection>
            </ContentBox>
        </Container>
    );
}

export default VerificationCosts;
